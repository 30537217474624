//Custom Color code
$darkgray-color: #a8a8a8;
$mosque-color: #004f40;
$nero-color: #2a2a2a;
$nerodark-color: #292929;
$gainsboro-color: #dfdfdf;
//navbar text color
$navText-color:#f8f9fa;
$navText-colorActive:#ffffff;
//filter text color
$filterTab-textColor:#8d8d8d;
//back button color
$backButton-bgColor:#ffffff;
$backButton-textColor:#004f40;
//Button color
$button-bgColor:#004f40;
$button-textColor:#ffffff;
//footer pp anc contact us color
$pp_button-bgColor:#ffffff;
$pp_button-textColor:#2a2a2a;



// google font
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

//custome font name
$lato-fontfamily: "Lato";
