@import "functions";
@import "variables";
@import "mixins";
@import "custom-variable";

* {
  font-family: $lato-fontfamily, sans-serif;
}

button,
.btn:focus,
.form-control:focus,
div.nav-item {
  outline: none !important;
  box-shadow: none;
}

.form-control:focus {
  outline: none;
  // box-shadow: none;
}

// cookie settings css
.cky-btn-accept {
  background-color: $button-bgColor !important;
  border-color: $button-bgColor !important;
}

.cky-btn-custom-accept {
  color: $button-bgColor !important;
  border-color: $button-bgColor !important;
}

.cky-switch input:checked + .cky-slider {
  background-color: $button-bgColor !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

html {
  height: 100%;
  position: relative;
}

body {
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  overscroll-behavior-x: none;
}

.kanda-app {
  min-height: 100%;
  padding-bottom: 60px;
  padding-top: 81px;
}

.kanda-logo {
  img {
    // width: 100%;
    height: 49px;
    display: block;
  }
}

.navbar {
  padding: 0;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    padding-top: 25px;
    color: $navText-color;
    font-size: 1.1rem;

    &.active {
      border-bottom: 5px solid $navText-colorActive;
      font-weight: bold;
    }
  }
}

h5 {
  font-family: $lato-fontfamily, sans-serif;
  font-weight: bold;
  color: $nero-color;
}

.btn-primary {
  color: $button-textColor;
  background-color: $button-bgColor;
  border-color: $button-bgColor;

  &:hover {
    color: $button-textColor;
    background-color: $button-bgColor;
    border-color: $button-bgColor;
  }
}

img {
  border: 0px;
}

.hasMedia {
  width: 45%;
  margin-left: 55%;
  padding-top: 40px;
  padding-right: 40px;
}

.mediaWrapper {
  position: fixed;
  width: 100%;
  height: 100%;

  .mapPointerIcon,
  .imagePointerIcon {
    position: absolute;
    left: -58px;
    top: -58px;
  }
}

.listing {
  .title {
    color: $mosque-color;
    font-size: 20px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    //height: 28px;
    overflow: hidden;
    text-align: left;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .news-img-small,
  .news-img-large,
  .proposal-img-small,
  .proposal-img-large,
  .proposal-img-landing {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .news-img-small {
    width: 180px;
    height: 100%;
  }

  .news-img-large {
    width: 280px;
    min-height: 180px;
    height: 100%;
  }

  .proposal-img-small {
    width: 160px;
    height: 310px;
  }

  .proposal-img-large {
    width: 280px;
    height: 280px;
  }

  .proposal-img-landing {
    width: 180px;
    height: 196px;
  }

  .bold {
    font-family: $lato-fontfamily;
    font-weight: bold;
  }

  p {
    font-size: 15px;
    font-family: $lato-fontfamily;
    color: $nero-color;
    line-height: 24px;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.listingLink {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.socialFeed {
  p {
    font-family: $lato-fontfamily;
    font-size: 14px;
    color: $nero-color;
    line-height: 22px;
    margin-bottom: 0;
  }

  .twitter,
  .facebook {
    font-weight: bold;
  }

  .twitter {
    color: #0c8bda;
  }

  .facebook {
    color: #3a589b;
  }
}

.filters {
  .btn-default {
    color: $filterTab-textColor;
    font-size: 1.11rem;
    font-weight: bold;
  }

  .btn-default.active {
    color: $mosque-color;
  }

  .searchButton {
    display: none;
  }

  .searchfield,
  .searchfield.show {
    display: inline;
  }
}

.serach {
  padding: 24px;
  padding-left: 56px;
  padding-top: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 20px;
}

.videoFrameWpr {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoFrame {
  margin: auto;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

// add by...

.homebanner {
  .hoverimg {
    object-position: center;
  }
}

.legal-content {
  .sub-heading {
    font-size: 1.2rem;
    font-weight: 700;
    font-family: $lato-fontfamily;
  }
}

.btn-project-details {
  min-width: 170px;
}

.pagination-wpr {
  position: relative;
  z-index: 111;
}

.mobile-box {
  display: none;
}

.postion-center {
  width: 500px;
  z-index: 1111;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  text-align: center;

  @media (max-width: 1099px) and (min-width: 992px) {
    left: -8%;
  }
}

.hub-mobile {
  margin: inherit;
  left: 30.4%;

  @media (max-width: 1199px) and (min-width: 992px) {
    left: -8%;
    margin: auto;
  }
}

.view-result-display {
  .minwdth {
    min-width: 170px;
  }
}

.policy-table,
.policy-table td,
.policy-table th {
  border: 1px solid black;
  padding: 5px;
}

.policy-table th {
  width: 33%;
}

.policy-table td {
  padding: 5px;
  vertical-align: top;
}

.policy-table {
  width: 100%;
  border-collapse: collapse;
}

.footer1 {
  .legal-box {
    border-radius: 10px 10px 0px 0px;
    padding: 0.6rem 1.5rem;
    background: $pp_button-bgColor;
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1%);
    display: inline-block;

    &.mobile-only {
      padding: 1.2rem 0rem 1.8rem;

      .link-tc {
        border-right: none;
        margin: 0rem;
      }
    }

    .link-tc {
      &:first-child {
        margin-left: 0px;
      }

      font-size: 16px;
      padding-right: 1rem;
      color: $pp_button-textColor;
      margin-left: 1rem;
      border-right: 1px solid $pp_button-textColor;

      &:last-child {
        padding-right: 0px;
        border-right: 0px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
    }
  }
}

// end
.proteck_password {
  width: 90%;
  margin: 0 auto;

  .form-control {
    background: $white;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid $nerodark-color;
    border-radius: 0 14px 0 14px;
    padding: 25px;
    min-width: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .proteck_password {
    width: 400px;
    margin: 0 auto;

    .form-control {
      min-width: 400px;
      width: 400px;
    }
  }
}

.footer {
  position: fixed;
  bottom: -1px;
  width: 100%;
  z-index: 111;

  .keep-me-updated {
    border-radius: 0 10px 0 0;
    padding: 0.6rem 2rem;
  }

  .support-wpr {
    width: 347px;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px 0 0 0;
    overflow: hidden;
    background-color: $backButton-bgColor;

    .supportPrompt {
      position: absolute;
      bottom: 40px;
      height: auto;
      width: 347px;
      right: 0;
      background: $backButton-bgColor;
      border-radius: 10px 0 0 0;
      box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.3);
      z-index: -1;

      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $mosque-color;
        margin-bottom: 0px;
        margin-top: 5px;
      }

      .desc {
        color: $nero-color;
        font-size: 15px;
        line-height: 18px;
      }

      .question {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: $nero-color;
      }
    }

    .support-developement {
      border-radius: 10px 0 0 0;
      padding: 0.6rem 1.5rem;
      color: $backButton-textColor;
    }

    .start-support {
      border-radius: 0 !important;
      padding: 0.6rem 2rem;
    }
  }
}

.btn-support-question {
  .btn-spt {
    width: 33.33%;
    padding-left: 2px;
    padding-right: 2px;

    &.btn-against {
      background: #ec5957;
      border: #ec5957;
      border-radius: 8px 0 0 8px;
    }

    &.btn-undecided {
      background: #5bbef9;
      border: #5bbef9;
    }

    &.btn-support {
      background: #5ce45c;
      border: #5ce45c;
      border-radius: 0 8px 8px 0;
    }
  }
}

.btn-support-question-border {
  .btn {
    border-radius: 0 8px;
    border-width: 2px;
  }
}

.surveyDetails {
  position: relative;
  padding-bottom: 80px;

  .kanda-card:first-child {
    border-bottom-left-radius: 0;
    border-bottom: 1px solid $gainsboro-color;
  }

  .kanda-card.mb-4 {
    margin-bottom: 0 !important;
  }

  .kanda-card.mt-2:last-child {
    margin-top: 0 !important;
    border-top-right-radius: 0;
  }

  .title {
    color: $mosque-color;
    font-size: 26px;
    line-height: 32px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
  }

  h4.title {
    color: $mosque-color;
    font-size: 22px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
    text-align: left;
  }

  .back-to-news {
    background: $white;
    position: absolute;
    border-radius: 0 8px 0 0;
  }

  p.post-details {
    font-size: 15px;
    line-height: 22px;
  }

  p {
    font-size: 14px;
    font-family: $lato-fontfamily;
    color: $nerodark-color;
    line-height: 22px;
  }
}

.k-contact {
  .title {
    color: $mosque-color;
    font-size: 26px;
    line-height: 32px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
  }

  .survey-icon {
    position: inherit;

    img {
      width: 24px;
    }
  }

  .project-info-text {
    font-size: 1rem;
  }
}

.supportSurvey {
  position: relative;

  &:first-child.kanda-card {
    border-bottom-left-radius: 0;
    border-bottom: 1px solid $gainsboro-color;
    border-radius: 0 30px 0 0;
  }

  .kanda-card.mb-4 {
    margin-bottom: 0 !important;
  }

  &:last-child.kanda-card {
    margin-top: 0 !important;
    border-top-right-radius: 0;
    border-radius: 0 0 0 30px;
  }

  .title {
    color: $mosque-color;
    font-size: 26px;
    line-height: 32px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
  }

  h4.title {
    color: $mosque-color;
    font-size: 22px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
    text-align: left;
  }

  .back-to-news {
    background: $white;
    position: absolute;
    border-radius: 0 8px 0 0;
  }

  p.post-details {
    font-size: 15px;
    line-height: 22px;
  }

  p {
    font-size: 14px;
    font-family: $lato-fontfamily;
    color: $nerodark-color;
    line-height: 22px;
  }

  .sp-header {
    border-bottom: 1px solid $gainsboro-color;
  }

  .sp-footer {
    border-top: 1px solid $gainsboro-color;
  }

  .sp-header,
  .sp-footer {
    border-left: 5px solid $mosque-color;
  }

  .result-wpr {
    width: 100px;
    height: 100px;
    border: 3px solid $mosque-color;
    border-radius: 50px;

    h4 {
      color: $mosque-color;
      font-size: 40px;
      text-align: center;
      line-height: 88px;

      .psign {
        font-size: 20px;
      }
    }
  }

  .next {
    .btn {
      border-radius: 0 8px;
      border-width: 2px;
    }
  }

  .support-survey-result {
    .your-answer {
      width: 20%;
      min-width: 120px;
      max-width: 160px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        border: 1px solid $gainsboro-color;
        left: 50%;
        top: 0;
        bottom: 0;
        z-index: 0;
      }

      p {
        background: $white;
        position: relative;
        z-index: 100;
      }
    }
  }
}

.survey-icon {
  background: $mosque-color;
  width: 38px;
  height: 38px;
  padding: 6px 7px;
  border-radius: 19px;
  position: absolute;
  z-index: 100;
}

.email-head {
  color: #004f40;
  text-align: center;
}

.survey-questions {
  &.active {
    opacity: 1;

    &:after {
      background: $darkgray-color;
    }

    .question {
      font-size: 24px;
      line-height: 36px;
    }

    .btn {
      border-radius: 0 8px;
      border-width: 2px;
    }

    .questionIndex {
      border: 2px solid $mosque-color;
      color: $mosque-color;
    }
  }

  &.previousNegative1 {
    opacity: 0;
    display: none;
  }

  &.previous {
    opacity: 1;

    .questionIndex {
      border: 2px solid $mosque-color;
      color: $mosque-color;
      background: $mosque-color;

      &:after {
        content: "";
        color: white;
        width: 5px;
        height: 10px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-left: -5px;
        margin-top: -3px;
      }
    }

    &:after {
      background: $mosque-color;
    }
  }

  opacity: 0.6;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    background: $darkgray-color;
    bottom: -26px;
    top: 41px;
    left: -19px;
  }

  &:last-child:after {
    bottom: 10px;
  }

  .questionIndex {
    position: absolute;
    width: 20px;
    height: 20px;
    color: $nero-color;
    border-radius: 10px;
    background: white;
    border: 2px solid $darkgray-color;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -28px;
    font-size: 12px;
    font-weight: bold;
  }

  .question {
    color: $nerodark-color;
    font-size: 16px;
    line-height: 19px;
    font-family: $lato-fontfamily;
    font-weight: normal;
    overflow: hidden;
  }

  .text-area {
    border: 2px solid $darkgray-color;
    width: 100%;
    border-radius: 0 10px 0 10px;
    padding: 10px;
    outline: none;

    &:focus {
      border: 2px solid $mosque-color;
    }
  }

  @media (max-width: 576px) {
    &:after {
      left: -10px;
      bottom: -20px;
      top: 31px;
    }

    .questionIndex {
      left: -18px;
    }
  }
}

.question-wpr {
  @media (max-width: 576px) {
    &.pl-5 {
      padding-left: 1.5rem !important;
    }
  }
}

.survey-result-wpr {
  .title {
    font-size: 24px;
  }
}

.survey-result {
  position: relative;
  padding-left: 40px;

  &:first-child:before {
    content: "";
    position: absolute;
    width: 2px;
    background: $mosque-color;
    bottom: 50px;
    top: -0px;
    left: 9px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    background: $mosque-color;
    bottom: -22px;
    top: 48px;
    left: 9px;
  }

  &:last-child:after {
    bottom: 10px;
  }

  .questionIndex {
    position: absolute;
    width: 20px;
    height: 20px;
    color: $mosque-color;
    border-radius: 10px;
    background: white;
    border: 2px solid $mosque-color;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 25px;
    font-size: 12px;
    font-weight: bold;
  }

  .question {
    color: $nerodark-color;
    font-size: 22px;
    line-height: 38px;
    font-family: $lato-fontfamily;
    font-weight: normal;
    overflow: hidden;
  }

  .answer {
    .percentage {
      font-size: 24px;
      line-height: 35px;
      color: $mosque-color;
      font-weight: bold;
      min-width: 70px;
    }

    .answerTitle {
      font-size: 18px;
      line-height: 20px;
      color: $mosque-color;
      font-weight: bold;
    }

    &.ans1 {
      .percentage,
      .answerTitle {
        color: rgb(236, 89, 87);
      }
    }

    &.ans2 {
      .percentage,
      .answerTitle {
        color: rgb(252, 193, 90);
      }
    }

    &.ans3 {
      .percentage,
      .answerTitle {
        color: rgb(41, 191, 255);
      }
    }

    &.ans4 {
      .percentage,
      .answerTitle {
        color: rgb(38, 228, 170);
      }
    }

    &.ans5 {
      .percentage,
      .answerTitle {
        color: rgb(93, 227, 93);
      }
    }
  }
}

//new modification
.container-one {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-one input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  z-index: 1;
  width: 25px;
  height: 25px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border: 2px solid #a8a8a8;
  border-radius: 0 8px;
}

@media (min-width: 768px) {
  /* On mouse-over, add a grey background color */
  .container-one input:hover ~ .checkmark {
    //background-color: red;
    *background-color: $mosque-color;
    border: 2px solid $mosque-color;
  }
}

/* When the radio button is checked, add a blue background */
.container-one input:checked ~ .checkmark {
  background-color: white;
  border: 2px solid $mosque-color;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-one input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-one .checkmark:after {
  display: none;
  left: 0px;
  top: 0px;
  width: 25px;
  height: 25px;
  background-image: url(../../images/icons/tickgreen.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  margin-top: 5px;
  margin-left: 2px;
}

.imageQuestion {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}

.chk-image {
  width: 150px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
}

/* The container */
.imageControl {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin-right: 3px;
  margin-bottom: 3px;
  height: 150px;
  width: 150px;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    // height: 0;
    // width: 0;
    z-index: 11;
    //newly added
    width: 100%;
    height: 100%;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 150px;
    width: 150px;
    border: 2px solid transparent;
    background-size: cover;
    border-radius: 0 10px;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(100%);

    .imageText {
      text-align: center;
      color: #6c757d;
      font-size: 16px;
      padding: 10px 0;
      white-space: break-spaces;
    }

    &::before {
      content: "";
      filter: grayscale(100%);
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    //new added
    //background-color: #fff;
    border: 3px solid $mosque-color;
    filter: grayscale(0);
    left: -2px;
    right: 0;
    top: -1px;
    bottom: 0;
    width: 150px;
    height: 150px;
    border-radius: 0 10px;
  }

  input:checked ~ .checkmark .imageText {
    color: $mosque-color;
  }

  input[type="checkbox"]:checked ~ .checkmark {
    filter: grayscale(0);
  }

  input[type="radio"]:checked ~ .checkmark {
    filter: grayscale(0);
  }
}

.thank-you {
  .thankyoutext {
    color: $mosque-color;
    font-size: 22px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
    text-align: left;
  }

  p {
    font-size: 16px;
    font-family: $lato-fontfamily;
    color: $nero-color;
    line-height: 19px;
  }

  label.tnc {
    color: $darkgray-color;
    font-size: 16px;
    font-family: $lato-fontfamily;
    font-weight: normal;
    overflow: hidden;
    text-align: left;
    padding-bottom: 3px;

    a {
      color: $mosque-color;
    }
  }

  .btn {
    border-radius: 0 8px;
    border-width: 2px;
  }

  .demographics-form {
    label {
      color: $mosque-color;
      font-size: 16px;
      font-family: $lato-fontfamily;
      font-weight: bold;
      overflow: hidden;
      text-align: left;
    }

    .form-control {
      box-shadow: none;
      border-radius: 0 8px;
      border: 2px solid #cdcdcd;

      &:focus {
        border: 2px solid $mosque-color;
      }
    }
  }
}

.itemDetails {
  position: relative;

  .title {
    color: $mosque-color;
    font-size: 26px;
    line-height: 32px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
  }

  h4.title {
    color: $mosque-color;
    font-size: 22px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
    text-align: left;
  }

  .back-to-news {
    background: $white;
    position: absolute;
    border-radius: 0 8px 0 0;
  }

  .bold {
    font-family: $lato-fontfamily;
    font-weight: bold;
  }

  p.post-details {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0.5rem !important;
  }

  p {
    font-size: 14px;
    font-family: $lato-fontfamily;
    color: $nero-color;
    line-height: 22px;
  }

  .wrap {
    white-space: nowrap;
  }

  img.right {
    float: right;
    margin-left: 15px;
    padding: 5px;
  }

  img.left {
    float: left;
    margin-right: 15px;
    padding: 5px;
  }
  img.pa-center {
    display:block;
    margin:0 auto;
    padding: 5px;
  }
}

.back-button-gen {
  background: $backButton-bgColor;
  position: fixed;
  border-radius: 0 8px 0 0;
  top: 100px;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.mapPointer {
  border: 2px solid #cdcdcd;
  border-radius: 0 8px;
  padding: 30px;
  text-align: center;
  margin: 20px 0;

  h4.title {
    color: $mosque-color;
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
    text-align: center;
  }

  .desc {
    color: $nero-color;
    font-size: 16px;
    line-height: 19px;
  }
}

.share-via {
  min-width: 186px;

  &.white {
    .share-via-text {
      color: $white;
    }
  }

  @media (max-width: 576px) {
    min-width: 32px;
  }
}

.sortByFilterWpr {
  button,
  p {
    font-size: 1rem;
  }

  .dropdown-toggle:after {
    width: 8px;
    height: 8px;
    margin-left: 10px;

    border: 0.12rem solid rgba(0, 0, 0, 0.8);
    border-left: none;
    border-top: none;
    transform: rotate(45deg);

    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(45deg);
    /* Firefox */
    -moz-transform: rotate(45deg);
    /* IE */
    -ms-transform: rotate(45deg);
    /* Opera */
    -o-transform: rotate(45deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  }
}

.timeline-wpr {
  margin-top: -8px;

  .container:first-child {
    .kanda-card {
      border-radius: 0 0 0 20px;
    }
  }

  .striped .kanda-card {
    background: #f8f8f8;
  }

  .container {
    position: relative;

    @media (min-width: 768px) and (max-width: 1199px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .circleIcon-wpr {
    padding: 3px;
    width: 24px;
    height: 24px;

    border-radius: 12px;
    background: $white;
    position: absolute;
    left: -45px;
    top: 24px;

    @media (min-width: 576px) and (max-width: 767px) {
      left: -18px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      left: -4px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      left: -7px;
    }

    .circleIcon {
      border: 3px solid $mosque-color;
      background: $white;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      margin: 0;
    }
  }
}

.timeline-cnt {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    background: $mosque-color;
    top: -30px;
    bottom: -31px;
    left: -50px;
    border-radius: 2.5px;

    @media (min-width: 576px) and (max-width: 767px) {
      left: -24px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      left: -24px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      left: -28px;
    }
  }

  &.current:after,
  &.disable:after {
    content: "";
    position: absolute;
    width: 5px;
    background: #b9b9b9;
    border-radius: 2.5px;
    top: 50px;
    bottom: -100px;
    left: -50px;
    z-index: -1;

    @media (min-width: 576px) and (max-width: 767px) {
      left: -24px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      left: -24px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      left: -28px;
    }
  }

  &.disable .listing {
    .title,
    .date,
    .desc {
      color: #a4a4a4;
    }
  }

  &.disable .note {
    color: #a4a4a4;
  }
}

.circleIcon {
  border: 3px solid $mosque-color;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-left: 30px;
}

.timeline-fork {
  padding: 0;
  overflow-x: auto;
  margin: 0 -45px;
  padding: 0 30px;

  @media (max-width: 576px) {
    overflow-x: hidden;
    margin: 0 -42px;
    padding: 0 28px;

    .fork-wpr {
      min-width: 100%;

      .fork-cnt {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }

  .fork-wpr {
    display: flex;
    margin: 0 -15px;

    .fork-cnt {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;
    }
  }

  .title {
    color: $nero-color;
    font-size: 20px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    height: 28px;
    overflow: hidden;
    text-align: left;
  }

  .forkTitle {
    color: #a4a4a4;
    font-size: 20px;
    font-family: $lato-fontfamily;
    font-weight: bold;
    overflow: hidden;
    text-align: left;
  }

  .forkDate {
    color: #d5d5d5;
    text-align: left;
  }

  .rejected-state {
    .title {
      color: #a4a4a4;
    }
  }

  .active {
    .forkTitle {
      color: $mosque-color;
    }

    .forkDate {
      color: $nero-color;
    }
  }
}

.or_ui {
  &:after {
    content: "";
    position: absolute;
    background: #979797;

    top: 13px;
    left: 10px;
    right: 0;
    height: 1px;
    z-index: -1;
  }

  position: relative;

  .text {
    padding: 0px 10px;
    color: #a1a1a1;
    background: #f7f7f7;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(247, 247, 247, 1) 45%,
      rgba(247, 247, 247, 1) 55%,
      rgba(255, 255, 255, 0) 100%
    );

    z-index: 999;
  }
}

.kanda-card {
  &.prime {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      background: $mosque-color;
      width: 5px;
    }

    p {
      font-size: 1.1rem;
    }
  }

  border-radius: 0 20px 0 20px;
  overflow: hidden;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
  border: none;

  h5,
  h6 {
    color: $mosque-color;
  }
}

.imagewithoutname {
  // width: 100%;
  display: block;

  .slide-preview-image {
    position: relative;
    // display: inline-block;
    // background: #eeeeee;
    text-align: center;

    .slide-name {
      background: $mosque-color;
      color: $white;
      font-size: 14px;
      line-height: 16px;
      padding: 5px 10px;
      text-align: right;
    }
  }

  .small-image {
    width: 100%;
  }

  .regular-image {
    height: 140px;
  }

  .slide-preview-image .btn {
    border: none;
  }

  .slide-preview-image-one {
    width: 100%;

    .p-2 {
      padding: 0rem !important;
    }
  }
}

.iconwithname {
  .text-wpr {
    flex-grow: 1;
  }

  .icon-image {
    width: 60px;
    padding: 5px;
  }

  ul {
    width: 100%;
    word-wrap: none;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    width: 50%;
    display: inline-block;
    color: $mosque-color;
    font-weight: bold;
  }

  @media (max-width: 580px) {
    li {
      width: 100%;
    }
  }
}

.border-top {
  border-top: 1px solid #cccccc;
}

.project-details {
  overflow-y: hidden;
  overflow-x: auto;

  .btn-default {
    color: #8a8a8a;
    font-size: 1.1rem;
  }

  .nav-item.back-button {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 1px;
      right: 0;
      background: $gainsboro-color;
      top: 20px;
      bottom: 20px;
    }
  }

  .nav-item.active {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      width: 60px;
      height: 5px;
      background: $mosque-color;
      bottom: 0;
      left: 50%;
      margin-left: -30px;
    }

    .btn-default {
      color: $mosque-color;
      font-weight: bold;
    }
  }

  .variable-width {
    .slick-list {
      padding: 0px 30px;
    }

    // .slick-disabled {
    //   opacity: 0;
    // }
    .slick-prev,
    .slick-next {
      background: $mosque-color;
      width: 30px;
      height: 76px;
      z-index: 1;
    }

    .slick-prev {
      left: 0px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 10%);
    }

    .slick-next {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 10%);
      right: 0px;
    }
  }
}

.li-fontSize {
  li {
    font-size: 14px;

    &:after {
      font-size: 14px;
    }
  }
}

.project-details-cnt {
  ul {
  }

  li {
    list-style: none;
    position: relative;
    padding-bottom: 5px;

    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      top: 10px;
      left: -30px;
      background: $mosque-color;
    }
  }

  // p {
  //   img {
  //     width: 100% !important;
  //     height: 100% !important;
  //     display: block;
  //   }
  // }

  &.additional-copy {
    font-size: 13px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.start-now {
  border-radius: 0 10px 0 10px;
  width: 280px;
  border: 2px solid;
  font-weight: bold;
}

.start-now-message {
  color: $button-textColor;
  font-size: 1.05rem !important;
}

.carouselWrapper {
  width: 400px;
  height: 550px;
  position: fixed;
  left: 25%;
  margin-left: -200px;
  top: 50%;
  margin-top: -188px;
  *margin-top: -275px;

  @media (min-width: 1025px) and (max-width: 1440px) {
    height: 350px;
  }

  .kanda-card {
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 992px) {
    .carouselContent {
      height: 420px;
      overflow-y: auto;

      h6 {
        font-size: 1.3rem;
      }
    }

    .kanda-card {
      margin-top: 3rem !important;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .title {
    font-size: 26px;
    line-height: 29px;
    color: $nero-color;
  }

  .desc {
    font-size: 16px;
    line-height: 20px;
    color: $nero-color;
  }
}

.support-cards {
  .listing {
    min-height: 200px;
  }
}

// .image-container{
//     .leftimg{
//         overflow: hidden;
//         .hoverimg{
//             display: block;
//             width: 100%;
//             height: 100vh;
//             object-fit: cover;
//             transition: all 300ms linear;
//             transform: scale(1);
//             &:hover{
//                 transform: scale(1.1);
//             }
//         }
//     }
// }
.hoverimg {
  width: 100%;
  display: block;
}

@media (min-width: 1024px) {
  .image-container {
    .leftimg {
      overflow: hidden;

      .hoverimg {
        display: block;
        width: 100%;
        height: 100vh;
        object-fit: cover;

        &.animation {
          transition: all 300ms linear;
          transform: scale(1);

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

// @media (min-width:768px){
//     .card-listingHeight{
//         height: 180px;
//         overflow: hidden;
//     }
// }

@media (max-width: 575px) {
  .do-support-btn {
    &.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
      background-color: white;
      color: $mosque-color;
      box-shadow: none;

      .icon {
        background-image: url("../../images/icons/greenchevron.svg");
      }
    }
  }

  .hero-banner {
    margin-top: -17px;
  }

  .container-top {
    margin-top: -17px;
  }

  .image-map {
    margin-top: -15px;
  }

  .social-parent {
    overflow: hidden;

    z-index: 0;
    position: relative;

    .pm-none {
      display: block;
      padding: 0px;
      width: 100%;
      overflow: auto;
      // margin-top: -15px;
      margin-bottom: 80px;

      .main-div {
        width: 100%;
        // display: block;
        background-color: white;
        margin: 0px;
        padding: 0px 5px;
        z-index: -1;

        .inside-col {
          margin-right: 15px;
          margin-left: 15px;
          margin-top: 20px;
          display: block;
          border-radius: 0 20px 0 20px;
          overflow: hidden;
          box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
          border: none;

          .kanda-card {
            margin: 0px;
            box-shadow: none;
            border-radius: 0px;
          }

          .mb-4 {
            margin-bottom: 0px !important;
          }

          .mt-2 {
            margin-top: 0px !important;
          }
        }

        .inside-col1 {
          display: block;
          margin-top: 15px;
          border-radius: 0 20px 0 20px;
          overflow: hidden;
          box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
          border: none;
          padding: 0px;
          margin-right: 15px;
          margin-left: 15px;
          margin-bottom: 32px;

          .kanda-card {
            margin: 0px;
            box-shadow: none;
            border-radius: 0px;
          }

          .mb-4 {
            margin-bottom: 0px !important;
          }

          .mt-2 {
            margin-top: 0px !important;
          }
        }
      }
    }
  }

  .prime-content {
    display: block !important;
    margin-top: -17px !important;
  }

  .survey-container.p-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .slider-question .selected.ans2 .copyText {
    font-size: 11px;
  }

  .footer {
    .keep-me-updated,
    .support-developement {
      border-radius: 0;
      text-align: left;
    }

    .keep-me-updated,
    .support-wpr,
    .support-wpr .support-developement {
      width: 100%;
    }

    .support-wpr .supportPrompt {
      z-index: 100;
      width: 100%;
      bottom: 65px;
    }

    .support-wpr .support-developement {
      padding: 1.2rem 2rem;
    }

    .keep-me-updated {
      padding: 1.2rem 2rem;
    }

    .support-wpr .start-support {
      padding: 1.4rem 2rem;
    }
  }

  .kanda-app {
    padding-bottom: 110px;
  }

  .inpartnership-text {
    text-align: center;
    font-size: 1.4rem;
  }

  .project-details {
    ul.nav-tabs {
      width: 100%;
    }

    ul {
      //width: 1100px;
      width: 700px;
      overflow: hidden;
    }
  }

  .project-config {
    padding: 0 !important;

    .kanda-card {
      border-radius: 0;
      margin-top: 0;

      &.prime:after {
        display: none;
      }

      .project-info-text {
        padding: 10px 0 !important;
      }

      .btn-project-details {
        width: 100%;
        padding: 1rem 1.5rem !important;
      }
    }
  }

  .praposal-hsb-mobile {
    padding: 1rem 3rem !important;
  }

  .flex-mob-column .pl-4 {
    padding: 1rem 1.5rem !important;
  }

  .listing {
    .proposal-img-landing,
    .proposal-img-small,
    .proposal-img-large,
    .news-img-large,
    .news-img-small {
      width: 100%;
      height: 200px;
    }
  }

  .timeline-cnt {
    &:after {
      left: -30px;
    }
  }

  .timeline-wpr {
    margin-left: 40px;

    .circleIcon-wpr {
      left: -25px;
    }

    .current:after,
    .disable:after {
      left: -30px;
    }
  }

  .navbar-brand {
    img {
      width: 125px;
      height: auto !important;
    }
  }

  .loading-div {
    padding: 10px !important;

    .main-div {
      display: block !important;

      .width-30 {
        width: 100% !important;
        padding-right: 0px !important;
      }

      .width-70 {
        width: 100% !important;
        margin-top: 10px;
        padding-left: 0px !important;
      }

      .first-div-new {
        margin-top: 10px;
        padding-left: 0px !important;
        margin-left: auto;
        padding-right: 10px;
      }

      .second-div {
        .main-div-new {
          .f1 {
            width: 50% !important;
          }

          .f2 {
            width: 50% !important;
          }
        }
      }
    }

    .archiv-div {
      display: block !important;
    }
  }

  .loading-div-praposal {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .praposal-display {
    display: block !important;

    .col-12 {
      padding: 0px !important;
    }
  }

  .padding-mobile {
    padding: 1rem !important;
  }

  .padding-mobile-new {
    padding: 1rem 1rem 2rem !important;
  }

  .share-via-wrap-mobile {
    flex-wrap: wrap;
  }

  .doyou-support-display {
    flex-wrap: wrap;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: initial !important;
    margin-right: initial !important;
    margin-bottom: initial !important;

    .pr-5 {
      padding-right: initial !important;
      padding: 0px 1.5rem 1.5rem !important;
    }

    .start-now-button {
      //width: 100%;
      padding: 0px 1.5rem 1.5rem !important;
    }

    .start-now {
      // width: 100%;
      // border-radius: 0px 0px 0px 20px;
    }
  }

  .fork-wpr-mobile {
    display: block !important;

    .hide-mobile {
      display: none;
    }
  }

  .hide-desktop {
    display: block !important;
    padding-bottom: 0.9rem;
    margin-right: 1rem;
    margin-left: 0.4rem;
  }

  .keepmeupdated-mobile {
    .form-control {
      min-width: auto;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid $mosque-color;
      -webkit-appearance: none;
    }
  }

  .surveyDetails-mobile {
    padding: 0px 15px 0px;
    padding-bottom: 140px !important;
  }

  .padding-left {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .kanda-card-mobile {
    .p-4 {
      //padding: 0.5rem !important;
      //padding-bottom:3rem !important;
      .question-wpr.pl-5 {
        padding-left: 1rem !important;

        .p-3 {
          padding: 0.5rem !important;
        }
      }
    }

    .p-d {
      padding-bottom: 1.5rem !important;
    }

    .pad-bot {
      padding-bottom: 3rem !important;
    }

    .pad-right {
      padding: 1.4rem !important;
    }

    .p-d0 {
      padding-bottom: 0rem !important;
    }
  }

  .pad-one {
    padding: 1rem !important;
  }

  .have-say-text {
    .pb-2 {
      padding: 0.375rem 0.75rem !important;
    }
  }

  .have-your-say-text {
    .pb-2 {
      padding: 0.1rem 0.75rem !important;
    }
  }

  .news-say-text {
    .pb-2 {
      padding: 0.18rem 0.1rem !important;
    }
  }

  .doyousupport-main {
    .p-5 {
      padding: 2.5rem 1rem 2rem !important;
    }
  }

  .flex-mobile-column {
    flex-direction: column;

    .text-right {
      text-align: left !important;
    }
  }

  .view-result-display {
    display: block !important;

    .padding-all {
      padding: 1rem !important;

      .pr-5 {
        padding-right: 0rem !important;
      }

      .pt-2 {
        padding-top: 0rem !important;
      }
    }

    a {
      width: 100%;
      padding: 1rem 3rem !important;
      justify-content: space-between !important;
      display: flex !important;
    }
  }

  .btn-support-question {
    .btn-lg {
      //  font-size: 0.9rem;
      padding: 10px 2px;
    }
  }

  .supportSurvey .next .btn {
    padding: 1rem 2rem;
    justify-content: space-between;
  }

  .hub-mobile {
    padding: 2.5rem 1rem 2rem !important;
  }

  .slide-preview-image-one {
    width: 100% !important;
  }

  .email-support {
    padding: 1rem 1.5rem 1.5rem !important;
    flex-direction: column;

    .email-support-sub {
      .pad {
        padding: 0px !important;
        padding-bottom: 1.5rem !important;
      }
    }
  }

  .email-sup-btn {
    width: 100% !important;
  }

  .search-mobile {
    width: 100%;

    .form-control {
      min-width: 100% !important;
    }
  }

  .timeline-pad-right {
    padding-right: 12px;
  }

  .sp-footer {
    .pad-mob {
      padding: 0rem !important;
      margin: 0rem !important;
    }

    .pad-mar {
      padding-left: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
  }

  .view-mobie {
    width: 49.5% !important;
    margin-right: 1% !important;
  }

  .download-mobie {
    width: 49.5% !important;
  }

  .archive-padding {
    padding: 1rem !important;
  }

  .archive-padding-main-sec {
    margin-left: 1rem !important;
    margin-top: 0.5rem;
  }

  // .request-callback{
  //     .pad{
  //         padding: 0.5rem 3rem !important;
  //     }
  // }
  .surveyDetails-support {
    padding-bottom: 120px !important;
  }

  .sup-sum-pad {
    padding: 0.5rem !important;
  }

  .break-word {
    word-break: break-word;
  }

  .calculation {
    padding: 0.5rem !important;
  }

  .survey-final-result {
    padding: 0.5rem !important;

    .row-block {
      display: block !important;
    }
  }

  .carouselWrapper-mobile {
    width: 100%;
    position: initial;
    margin: 0;
    margin-top: -30px;
    height: auto;
    padding: 0px 15px;
  }

  .hasMedia-mobile {
    padding-top: 0px !important;
  }
}

@media (max-width: 991px) {
  .filters {
    white-space: nowrap;
    overflow: auto;

    .searchButton {
      display: inline;
    }
  }

  .searchfield {
    display: none;

    &.show {
      display: block !important;
    }
  }

  .prime-content {
    display: block !important;
  }

  .view-result-display {
    .padding-all {
      .pr-5 {
        padding-right: 0rem !important;
      }
    }
  }
}

.CookieConsent {
  background: $mosque-color !important;
  color: $button-textColor !important;
  z-index: 11111 !important;

  #rcc-confirm-button {
    background: $button-bgColor !important;
    color: $button-textColor !important;
    font-size: 13px;
    font-weight: bolder;
    border-radius: 0 10px 0 10px !important;
    padding: 0.6rem 2rem !important;
  }
}

// survey responsive
@media (min-width: 980px) and (max-width: 1280px) {
  .hasMedia {
    width: 55%;
    margin-left: 45%;
  }
}

@media (max-width: 980px) {
  .hasMedia {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
    padding-top: 110%;
  }

  .mediaWrapper {
    height: 55%;
    overflow: auto;
  }
}

.ReactModal__Overlay {
  z-index: 1200 !important;
}

.ril__toolbar {
  height: 82px;
  background: none;
}

.rotate-left {
  .icon {
    transform: rotate(180deg);
  }
}

.btn {
  .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.greenchevron {
    .icon {
      background-image: url("../../images/icons/greenchevron.svg");
    }

    &:hover {
      .icon {
        background-image: url("../../images/icons/whitechevron.svg");
      }
    }
  }

  &.graychevron {
    .icon {
      background-image: url("../../images/icons/graychevron.svg");
    }

    &:hover {
      .icon {
        background-image: url("../../images/icons/whitechevron.svg");
      }
    }
  }
}

.slick-dots {
  bottom: -18px;
}

.keepmeupdated {
  .form-control {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
  }
}

.slider-question {
  width: 100%;
  // max-width: 500px;
  height: 10px;
  // *border: 1px solid #c3c3c3;
  // background-color: #ffcc00;
  margin: 75px 0px 7px;
  // display: flex;

  .slide-selector {
    font-size: 13px;
    font-weight: 700;
    top: -86px !important;
    width: 20%;
    height: 95px;
    text-align: center;
    -webkit-appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .slide-track {
    top: 0px;
    height: 3px;
    background-color: #e8e8e8;
    display: none;
  }

  .selected {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 70px;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      margin-left: -8px;
      margin-bottom: 6px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #333333;
      bottom: 10px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      margin: 0;
      border-top: 14px solid #333333;
      bottom: -1px;
    }

    .copyText {
      font-size: 18px;
      margin: 20px 0;
    }

    @media (max-width: 576px) {
      height: 90px;

      .copyText {
        font-size: 12px;
        margin: 10px 0;
        line-height: 18px;
      }
    }

    &.ans0 {
      .copyText {
        color: rgb(236, 89, 87);
      }

      &:after,
      &:before {
        border-top-color: rgb(236, 89, 87);
      }
    }

    &.ans1 {
      .copyText {
        color: rgb(252, 193, 90);
      }

      &:after,
      &:before {
        border-top-color: rgb(252, 193, 90);
      }
    }

    &.ans2 {
      .copyText {
        color: rgb(41, 191, 255);
      }

      &:after,
      &:before {
        border-top-color: rgb(41, 191, 255);
      }
    }

    &.ans3 {
      .copyText {
        color: rgb(38, 228, 170);
      }

      &:after,
      &:before {
        border-top-color: rgb(38, 228, 170);
      }
    }

    &.ans4 {
      .copyText {
        color: rgb(93, 227, 93);
      }

      &:after,
      &:before {
        border-top-color: rgb(93, 227, 93);
      }
    }
  }

  span.options {
    width: 20%;
    height: 6px;
    // border: 1px solid #cc0000;
    position: absolute;
    display: block;
  }

  span.options:nth-child(5) {
    background: rgb(252, 193, 90);
  }

  span.options:nth-child(4) {
    background: rgb(236, 89, 87);
  }

  span.options:nth-child(6) {
    background: rgb(41, 191, 255);
  }

  span.options:nth-child(7) {
    background: rgb(38, 228, 170);
  }

  span.options:nth-child(8) {
    background: rgb(93, 227, 93);
  }

  .hint-text > p {
    color: $nero-color;
    font-size: 13px;
    line-height: 16px;
  }
}

.loading-div {
  .main-div {
    display: flex;
    background-color: white;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;

    .width-30 {
      width: 30%;
    }

    .width-70 {
      width: 70%;
      padding-left: 10px;
    }

    .first-div {
      width: 30%;
      padding-right: 10px;
    }

    .first-div-new {
      width: 30%;
      padding-left: 10px;
    }

    .second-div {
      width: 70%;

      .main-div-new {
        display: flex;
        margin-top: 10px;

        .f1 {
          width: 30%;
        }

        .f2 {
          width: 70%;
          margin-left: 10px;
        }
      }
    }
  }

  .padding-first {
    padding-left: 10px;
    padding-top: 10px;
  }

  .padding-flex {
    padding: 10px;
    display: flex;
    background-color: white;
  }

  .archiv-div-main {
    background-color: white;
    padding: 0px 10px 10px;
  }

  .archiv-div {
    padding: 0px 10px 10px;
    background: white;

    .f11 {
      width: 30%;
      margin-right: 10px;
      display: flex;
    }

    .f12 {
      width: 30%;
      display: flex;
    }
  }
}

.praposal-display {
  display: flex;
}

.pm-none {
  display: none;
}

._2p3a {
  width: 100% !important;
}

.prime-content {
  display: flex;
}

.hide-desktop {
  display: none;
}

.pad-des {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.proj-det-pad-botm {
  padding-bottom: 0.5rem;
}

.carouselWrapper .slick-slide img {
  height: 50%;
}

.carouselWrapper {
  .slick-dots {
    bottom: -33px;
  }
}

.Toastify__toast--success {
  background: $mosque-color !important;
}

/*Cookie Yes */

@media screen and (max-width: 540px) {
  .cky-button-wrapper .cky-btn:last-child {
    margin-left: 70px;
    margin-top: 5px;
  }

  .cky-classic .cky-button-wrapper {
    margin-top: 10px !important;
  }

  .cky-detail-wrapper {
    margin-top: 10px !important;
  }

  .cky-consent-bar.cky-classic {
    padding: 10px !important;
  }
}

@media screen and (max-width: 320px) {
  .cky-button-wrapper .cky-btn:last-child {
    margin-left: 0px;
    margin-top: 5px;
  }
}

.news-details {
  overflow-y: hidden;
  overflow-x: hidden;

  .btn-default {
    color: #8a8a8a;
    font-size: 1.1rem;
  }

  .nav-item.back-button {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 1px;
      right: 0;
      background: $gainsboro-color;
      top: 20px;
      bottom: 20px;
    }
  }

  .nav-item.active {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      width: 60px;
      height: 5px;
      background: $mosque-color;
      bottom: 0;
      left: 50%;
      margin-left: -30px;
    }

    .btn-default {
      color: $mosque-color;
      font-weight: bold;
    }
  }

  .variable-width {
    .slick-list {
      padding: 0px 30px;
    }

    .slick-prev,
    .slick-next {
      width: 30px;
      height: 40px;
      padding: 10px 0;
      z-index: 1;
      background: #fff;

      &:after {
        content: "";
        width: 28px;
        height: 36px;
        display: block;
        background-color: #fff;
      }

      &:before {
        content: "";
        color: $mosque-color;
        width: 30px;
        height: 20px;
        display: block;
        background-size: 26%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .slick-prev {
      left: 0px;
      border-right: 1px solid #ccc;

      &:before {
        content: "";
        background-image: url("../../images/icons/left.svg");
      }
    }

    .slick-next {
      right: 0px;

      border-left: 1px solid #ccc;

      &:before {
        content: "";
        background-image: url("../../images/icons/right.svg");
      }
    }
  }
}

.modal-open .modal {
  z-index: 2000;
}

.modal {
  .modal-dialog {
    max-width: 800px;
  }

  @include media-breakpoint-down(md) {
    .modal-dialog {
      padding: 2rem;
    }
  }

  .modal-content {
    border-radius: 0 10px;
    overflow: hidden;
  }

  .modal-body {
    .close {
      position: absolute;
      right: 15px;
      top: 10px;
      z-index: 1;
    }

    .icon {
      color: $mosque-color;
      width: 20px;
      height: 20px;
    }

    .btn-primary,
    .btn-close,
    .btn-outline-primary {
      width: 220px;
      border-width: 2px;
      border-radius: 0 10px;
    }

    .title {
      color: $mosque-color;
      font-size: 26px;
      line-height: 32px;
      font-family: $lato-fontfamily;
      font-weight: bold;
      overflow: hidden;
    }
  }
}

.notification-modal {
  .modal-dialog {
    max-width: 800px;
  }

  .support-mobilisation-image {
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    min-height: 200px;
  }

  @include media-breakpoint-down(sm) {
    .support-mobilisation-image {
      height: 200px;
    }

    h3.title.pt-5 {
      padding-top: 1.5rem !important;
      font-size: 22px;
    }
  }
}

.email-template-modal {
  .email-content {
    background-color: #f7f7f7;
    padding: 40px;
  }

  .btn {
    width: 230px !important;
    font-size: 16px;
  }

  .title {
    text-align: center;
    font-size: 20px;
  }

  .sub-title {
    text-align: center;
    color: #2a2a2a;
    font-size: 15px;
    font-family: $lato-fontfamily, sans-serif;
    line-height: 24px;
    font-weight: bold;
  }

  .white-box {
    background-color: #fff;
    color: #212529;
    padding: 25px;
    border-radius: 10px;
  }

  .content {
    text-align: center;
  }

  .last-content {
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    color: #004f40;
    padding: 5px;
  }

  @include media-breakpoint-down(md) {
    .email-content {
      background-color: #f7f7f7;
      padding: 30px 15px;
    }

    .title {
      font-size: 18px !important;
    }

    .sub-title {
      font-size: 13px;
    }

    p,
    .white-box {
      font-size: 13px;
    }

    .btn {
      width: 230px !important;
      font-size: 14px;
    }
  }
}
@media (max-width: 991px) {
  .footer {
    display: none;
  }
}
.keep-updated {
  margin: auto;
  width: 100%;
  font-size: 16px;
  background-color: #ffffff;
  border: none;
  color: #2a2a2a;
  text-align: center;
  padding: 0%;
}
@media (min-width: 992px) {
  .keep-updated {
    display: none;
  }
}

.navbar-mobile {
  max-height: 600px;
  overflow: auto;
  padding: 0%;
}


