:root {
  --base-duration: 500ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}
$pp_button-bgColor:#ffffff;
$pp_button-textColor:#2a2a2a;
.navbar-toggler {
  width: 60px;
  overflow: hidden;
  position: relative;
  &:focus {
    outline: none;
  }
}
.navbar-toggler-icon {
  right: 30px;
  width: 60px;
  height: 2px;
  background: #fcfcfc;
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  *transition: 0.5s;
}

.navbar-toggler.collapsed .navbar-toggler-icon:before {
  top: -10px;
}

.navbar-toggler.collapsed .navbar-toggler-icon:after {
  top: 10px;
}

.navbar-toggler-icon {
  cursor: pointer;
}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background: #fcfcfc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.navbar-toggler-icon {
  background: rgba(0, 0, 0, 0);
  *box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.navbar-toggler.collapsed .navbar-toggler-icon:before {
  top: -10px;
  transform: none;
}
.navbar-toggler-icon:before {
  *top: initial;
  transform: rotate(45deg);
}

.navbar-toggler.collapsed .navbar-toggler-icon:after {
  top: 10px;
  transform: none;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.navbar-toggler-icon:after {
  top: 0;
  transform: rotate(135deg);
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

//   .navbar-toggler.collapsed .navbar-toggler-icon {
//     background:rgba(0,0,0,0);
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
//   }

//   .navbar-toggler.collapsed .navbar-toggler-icon:before {
//     top: 0;
//     transform:rotate(45deg);
//   }

//   .navbar-toggler.collapsed .navbar-toggler-icon:after {
//     top: 0;
//     transform:rotate(135deg);
//     box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
//   }

//iMG HOVER

.hover {
  // --x: 0;
  // --y: 0;
  // --d: 50;

  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;

  // &:hover .hover__image-wrapper {
  //     transform:
  //         scale(1.025)
  //         translate(
  //         calc(var(--x) / var(--d) * 1px),
  //         calc(var(--y) / var(--d) * 1px)
  //         );
  // }
}

//    .hover__image {
//      --d: 20;
//      transform:
//        translate(
//          calc(var(--x) / var(--d) * 1px),
//          calc(var(--y) / var(--d) * 1px)
//        );
//    }

.hover__image-wrapper {
  height: 100%;
  left: 0%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 100%;
  &:hover {
    transform: scale(1.1);
  }
}

.hover__image {
  height: 100%;
  left: 0%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0%;
  transition: transform var(--base-duration) var(--base-ease);
  user-select: none;
  width: 100%;
}

@media (min-width: 576px) and (max-width: 767px) {
  .carouselWrapper-main {
    margin-top: 110px;
  }
  .carouselWrapper {
    width: 100%;
    margin: 0px auto;
    position: initial;
  }
  .hasMedia {
    padding-top: 45%;
  }
  .listing {
    .news-img-large {
      width: 210px;
    }
  }
  .listing {
    .proposal-img-landing,
    .proposal-img-small,
    .proposal-img-large,
    .news-img-large,
    .news-img-small {
      width: 100%;
      height: 240px;
    }
  }

  .social-parent {
    .pm-none {
      display: block;
      padding: 0px;
      width: 100%;
      overflow: auto;
      // margin-top: -15px;
      margin-bottom: 80px;
      .main-div {
        //width:  100%;
        // // display: block;
        // background-color: white;
        // margin: 0px;
        // padding: 0px 5px;

        width: 540px;
        display: flex;
        background-color: white;
        margin: 0px auto;
        padding: 0px 5px;

        .inside-col {
          // padding: 0px 2px 0px 0px;
          // width: 100%;
          // display: inline-block;
          // border-radius: 0 20px 0 20px;
          // overflow: hidden;
          // box-shadow: 0 2px 64px 0 rgba(0, 0, 0 , 0.10);
          // border: none;
          // margin-top: 10px;

          margin: 5px;
          width: 253px;
          display: inline-block;
          box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
          border: none;
          border-radius: 0 20px 0 20px;
          overflow: hidden;

          .kanda-card {
            margin: 0px;
            box-shadow: none;
            border-radius: 0px;
          }
          .mb-4 {
            margin-bottom: 0px !important;
          }
          .mt-2 {
            margin-top: 0px !important;
          }
        }
        .inside-col1 {
          // width: 100%;
          // display: inline-block;
          // margin-top: 15px;
          // border-radius: 0 20px 0 20px;
          // overflow: hidden;
          // box-shadow: 0 2px 64px 0 rgba(0, 0, 0 , 0.10);
          // border: none;
          // padding: 10px;

          margin: 5px;
          width: 253px;
          display: inline-block;
          box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.1);
          border: none;
          border-radius: 0 20px 0 20px;
          overflow: hidden;

          .kanda-card {
            margin: 0px;
            box-shadow: none;
            border-radius: 0px;
          }
          .mb-4 {
            margin-bottom: 0px !important;
          }
          .mt-2 {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .carouselWrapper-main {
    margin-top: 110px;
  }
  .doyou-support-display {
    flex-direction: column;
    .start-now {
      margin: 20px auto 5px;
    }
  }
  .pagination-wpr {
    padding-bottom: 10px;
    z-index: 11;
    position: relative;
    .pagination {
      justify-content: center !important;
    }
  }
  .m-none {
    display: none;
  }
  .flex-mob-column {
    flex-direction: column;
    .share-via {
      margin-top: 8px;
      margin-bottom: 6px;
      .share-via-text {
        text-align: left !important;
      }
    }
    .pl-4 {
      padding-left: 0rem !important;
    }
  }

  .flex-mobile-column {
    flex-direction: column;
  }

  .itemDetails {
    .title {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .prime-content {
    .image-container {
      height: auto !important;
      display: block;
    }
  }

  .listing {
    .proposal-img-small,
    .proposal-img-large {
      width: 100%;
      height: 200px;
    }
  }

  .addition_cls {
    .mob-p0 {
      padding-right: 0px !important;
    }
  }

  .mob-center {
    //margin: 10px auto;
    margin: 25px auto 10px;
  }
  .w-100 {
    width: 100%;
  }
  .mob-left {
    justify-content: flex-start !important;
  }

  .thank-you .demographics-form .form-control {
    min-width: auto;
  }
  .d-flex-mobile {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .postion-center {
    display: none;
  }
  .mobile-box {
    display: block;
    .footer1 {
      background-color: white;
      text-align: center;
      margin-bottom: -30px;
      min-height: 300px;
      .legal-box {
        border-radius: 0px;
        box-shadow: none;
      }
    }
  }

  .prime-content {
    .image-container {
      height: auto !important;
      display: block;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .carouselWrapper-main {
    width: 60%;
    position: initial;
    margin: 0px auto;
    height: auto;
  }

  .carouselWrapper {
    width: 100%;
    position: initial;
    margin: 0px auto;
    height: auto;
  }
  .hasMedia-mobile {
    padding-top: 0px;
  }
  .listing {
    .news-img-large {
      width: 190px;
    }
  }
  .flex-mob-column {
    flex-direction: column;
    .share-via {
      margin-top: 8px;
      .share-via-text {
        text-align: left !important;
      }
    }
  }
  .kanda-card {
    .socialFeed {
      padding: 12px !important;
    }
  }

  .listing {
    .proposal-img-small {
      width: 280px;
      height: 280px;
    }
  }
  .mob-center {
    margin: 10px auto;
  }
}
