// .kanda-app {
//     min-height: 100%;
//     padding-bottom: 0 !important;
//     padding-top: 81px;
// }

.prime-content {
    position: fixed;
    bottom: 0;
    top: 80px;
    left: 0;
    right: 0;

    .image-container {
        width: 40%;
        height: 100%;
        display: inline-block;
    }

    .content-wrapper {
        width: 60%;
        height: 100%;
        overflow: auto;
        display: inline-block;
    }
}

@media (max-width: 991px) {
   .content-wrapper{
       margin-top:-6px;
   }
    .prime-content {
        position: relative;
        margin-top: -1px;
        top: 0;

        .image-container {
            width: 100%;
            height: 300px;
            display: inline-block;
        }

        .content-wrapper {
            width: 100%;
            height: 100%;
            overflow: auto;
            display: inline-block;
        }
    }
}

.navbar-dark .navbar-toggler,
.navbar-light .navbar-toggler {
    color: rgba(255, 255, 255, 1);
    border: none;
}

.navbar-collapse.collapse.show {
    padding-bottom: 30px;
    .nav-item {
        display: inline-block !important;
        .nav-link {
            text-align: center;
        }
        .nav-link.active {
            border: none;
        }
    }
}
